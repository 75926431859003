<template>
  <div>
    <Header
      :showBackButton="true"
      :showLogo="false"
      :title="'Insumos'"
      :menuColor="'#fff'"
      :background="'var(--blue1)'"
      class="page-anim1"
    />

    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div
        v-show="showIngredientModal"
        @click="closeIngredientModal"
        class="bg-cover"
      ></div>
    </transition>

    <transition
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
    >
      <IngredientModal
        :ingredientData="selectedIngredient"
        :editing="true"
        @saveIngredient="saveUserIngredient"
        @close="closeIngredientModal"
        v-show="showIngredientModal"
      />
    </transition>

    <section>
      <div
        v-show="loading"
        class="flex-center desktop12 tablet12 mobile12 mt-3"
      >
        <div class="loading2"></div>
      </div>

      <div v-show="!loading" class="container ingredient">
        <div class="title-holder" style="animation-delay: 500ms">
          <BasketIn class="icon" />
          <h1>Insumos</h1>
        </div>

        <div class="flex-center mt-3" v-show="ingredients.length <= 0">
          <span class="text-primary text-desc"
            >Você ainda não possui nenhum insumo.</span
          >
        </div>

        <table
          v-show="ingredients.length > 0"
          class="ingredients"
          style="animation-delay: 500ms"
        >
          <thead>
            <tr>
              <th>Nome</th>
              <th>Peso</th>
              <th>Custo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in ingredients" :key="index">
              <td>
                {{ item.name }}
              </td>
              <td>{{ item.weight }} gr</td>
              <td>{{ item.price | currency }}</td>
              <td>
                <div class="table-action-holder">
                  <div title="Editar ingrediente">
                    <EditIcon
                      @click="editIngredient(item, index)"
                      fillColor="var(--dark4)"
                    />
                  </div>
                  <div title="Excluir ingrediente">
                    <DeleteIcon
                      @click="deleteIngredient(item, index)"
                      fillColor="var(--red1)"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          @click="getIngredients"
          v-show="!allIngredientsLoaded"
          class="btn desktop3 tablet4 mobile6"
        >
          <span v-show="!loadingMore">Carregar mais</span>
          <div v-show="loadingMore" class="loading2 white"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import http from "@/http";
import authentication from "../util/authentication";

import Header from "../components/Header";
import IngredientModal from "../components/IngredientModal";

import BasketIn from "vue-material-design-icons/BasketFill.vue";
import EditIcon from "vue-material-design-icons/PencilOutline.vue";
import DeleteIcon from "vue-material-design-icons/DeleteOutline.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
export default {
  components: {
    Header,
    IngredientModal,
    BasketIn,
    EditIcon,
    DeleteIcon,
    PlusIcon,
  },
  data() {
    return {
      loading: true,
      loadingMore: false,
      page: 0,
      take: 30,
      allIngredientsLoaded: false,
      showIngredientModal: false,
      selectedIngredient: null,
      ingredients: [],
    };
  },
  created() {
    this.getIngredients();
  },
  methods: {
    getIngredients() {
      if (this.loadingMore) return;

      this.loadingMore = true;

      const params = {
        page: this.page,
        take: this.take,
        orderBy: "-name",
        ingredients: [],
      };

      http
        .post("ingredient/list", params, authentication.getHeaders())
        .then((response) => {
          this.ingredients = [...this.ingredients, ...response.data.list];

          if (this.page < response.data.pages - 1) this.page++;
          else this.allIngredientsLoaded = true;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    saveUserIngredient(ingredient) {
      this.ingredients[this.selectedIngredient?.index] = ingredient;
      this.closeIngredientModal();
      this.$swal({
        toast: true,
        icon: "success",
        title: "Ingrediente atualizado!",
        position: "top-right",
        showConfirmButton: false,
        timer: 4000,
      });
    },
    deleteIngredient(ingredient, index) {
      this.$swal
        .fire({
          icon: "error",
          title: "Apagar ingrediente",
          text: `Tem certeza que deseja apagar o ingrediente "${ingredient.name}"? Esta ação não poderá ser desfeita.`,
          confirmButtonText: "Apagar",
          confirmButtonColor: "var(--red1)",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            http
              .delete(
                `ingredient/${ingredient.id}`,
                authentication.getHeaders()
              )
              .then(() => {
                this.$swal({
                  toast: true,
                  icon: "success",
                  title: "Ingrediente excluído!",
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 4000,
                });
                this.ingredients.splice(index, 1);
              });
          }
        });
    },
    editIngredient(ingredient, index) {
      this.selectedIngredient = ingredient;
      this.selectedIngredient.index = index;
      this.showIngredientModal = true;
    },
    closeIngredientModal() {
      this.selectedIngredient = null;
      this.showIngredientModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container.ingredient {
  width: 90%;
  max-width: 900px;
}

.title-holder {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
  &.prepare {
    margin-top: 0;
    h1 {
      margin-top: 7px;
    }
  }
  span {
    position: relative;
    margin-right: 15px;
  }
  svg {
    color: var(--secondary);
  }
  h1 {
    font-family: fontLight;
    font-size: 1.2em;
    color: var(--secondary);
  }
}

.material-design-icon.icon {
  width: 2.4em;
  height: 2.4em;
  color: var(--secondary);
}

.ingredients {
  position: relative;
  width: 100%;
  margin-top: 5px;
  border-radius: 12px;
  tr {
    &:first-child {
      border-radius: 12px 12px 0 0;
      td:last-child {
        border-radius: 0 12px 0 0;
      }
    }
    position: relative;
    width: 100%;
    padding-left: 15px;
    th:first-child,
    td:first-child {
      border-radius: 6px 0 0 6px;
    }
    th:last-child,
    td:last-child {
      border-radius: 0 6px 6px 0;
    }
    th {
      padding: 12px 8px;
      text-align: left;
    }
    td {
      padding: 12px 15px;
      color: var(--dark4);
      &:nth-child(1) {
        font-family: fontMedium;
        font-size: 1.1em;
      }
    }
  }
  tbody tr {
    &:nth-child(odd) {
      background-color: #ffebe8;
    }
  }
}

.table-action-holder {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-right: 5px;
  svg {
    cursor: pointer;
  }
}

.btn {
  border: 1px solid var(--primary);
  background-color: #fff;
  margin: 45px auto 0px auto;
  padding: 15px 29px;
  span {
    font-family: fontRegular;
    color: var(--primary);
  }
}
</style>
